// Brand Style One 

.brand-style-1 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
        flex-basis: 25%;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        margin: 0px 0px -2px -2px;
        border-color: #d8d8d8; //var(--color-border);
        padding: 40px 0 40px 0;
        text-align: center;
        @media #{$large-mobile} {
            flex-basis: 33.33%;
        }
        @media #{$small-mobile} {
            flex-basis: 50%;
        }
        background-color: rgb(245, 248, 250) !important;
        opacity: 0.8;
        a {
            display: block;
            img {
                opacity: 0.8;
                transition: 0.3s;
            }
        }
        &:hover {
            a {
                img {
                    opacity: 1;
                }
            }
        }
    }
}

// Brand Style Two 
.brand-style-2 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
        flex-basis: 16.666%;
        text-align: center;

        @media #{$md-layout} {
            flex-basis: 25%;
        }
        
        @media #{$sm-layout} {
            flex-basis: 25%;
        }

        @media #{$large-mobile} {
            flex-basis: 33.33%;
        }

        @media #{$small-mobile} {
            flex-basis: 50%;
        }

        a {
            display: block;
            img {
                opacity: 0.8;
                transition: 0.3s;
            }
        }
        &:hover {
            a {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &.variation-2 {
        li {
            flex-basis: 25%;
            text-align: center;
            @media #{$sm-layout} {
                flex-basis: 33.33%;
            }
            @media #{$small-mobile} {
                flex-basis: 50%;
            }
        }
    }
}






























